import React, { useEffect } from "react";
import GalleryHome from "./GalleryHome";
import { LuxuryBox } from "./LuxuryBox";
import { ProductInformation } from "./ProductInformation";
import Video from "./Video";
import BannerSlider from "./BannerSlider";
import Testimonial from "./Testimonial";
import ShopModal from "../../Components/ShopModal";
import { useLocation } from "react-router-dom";

const Home = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Makkusé";
  }, [location.pathname]);
  return (
    <main className="page-Home">
      <BannerSlider />
      <ProductInformation />
      <Video />
      <LuxuryBox />
      <Testimonial />
      <GalleryHome />
      <ShopModal />
    </main>
  );
};

export default Home;
