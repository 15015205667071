import React from "react";
import { Link } from "react-router-dom";
/* import brassSpoon from "../../images/brass-spoon.png";
import loktaPaperBenifits from "../../images/lokta-paper-benifits.png";
import story from "../../images/story.png"; */
export default function ProductionProcess() {
  return (
    <section className="section-production-process">
      <div className="container">
        <div className="row">
          <div className="col-md-6 order-second">
            <div className="text-container odd">
              <div className="header-box underline">
                <h2>Significance of Copper Spoon</h2>
              </div>
              <div className="para">
                <p>
                  You will see copper hints throughout our branding, an ode to
                  the unique copper elements that are an integral part of
                  ceremonies and occasions celebrated within the Newar and the
                  wider Nepali community, especially in the signature
                  handcrafted copper spoon.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 order-firsts">
            <div className="image-container odd">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/brass-spoon.png`}
                alt="Myth of brass spoon"
                style={{ objectFit: "contain" }}
              />
            </div>
          </div>
          <div className="col-md-6 order-third">
            <div className="image-container even">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/lokta-paper-benifits.png`}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 order-fourth">
            <div className="text-container even">
              <div className="header-box underline">
                <h2>Lokta paper benefits</h2>
              </div>
              <div className="para">
                <p>
                  Artisans from the heart of Himalayas in Nepal, render a
                  traditional process of ancient paper making with modern
                  fashion and style to make the Lokta paper. The Lokta paper is
                  characterized by high paper quality, prized with superior
                  durability and rich texture. Our packages are reusable for
                  decorational and storage purposes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 order-sixth">
            <div className="text-container odd">
              <div className="header-box underline">
                <h2>Makkusé Blogs</h2>
              </div>
              <div className="para">
                <p>
                  There are so many exciting conversations waiting to happen
                  within the Nepali dessert space – here are some thoughts from
                  our team, clients and enthusiasts! Do have a read and leave
                  your thoughts!
                </p>
                <Link
                  to="/blog"
                  className="read-more-link"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 order-fifth">
            <div className="image-container odd">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/story.png`}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
