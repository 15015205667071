import React from "react";
import { Link } from "react-router-dom";
import CardPrimary from "../../Components/CardPrimary";
/* import smallCookies from "../../images/cookies-small.png";
import pustakariSmall from "../../images/pustakari-small.png";
import smallGundPak from "../../images/gudpak-small.png";
import luxuryBox from "../../images/makkuse-lux.jpeg"; */
export const ProductInformation = () => {
  return (
    <section className="section-product-information">
      <div className="container">
        <div className="text-container">
          <div className="header-box underline">
            <h2>Find a freshly made product for you</h2>
          </div>
          <div className="para">
            <p>
              With our selection of Pustakari, Gundpak and Cookies travel back
              in time to a nostalgic past, journey through a playful present and
              get ready to leap into an innovative future.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <CardPrimary
              // image={smallCookies}
              image={`${process.env.REACT_APP_BASE_URL}/images/cookies-small.png`}
              alt="Cookies"
              title="Cookies"
              link="product-cookies"
            />
          </div>
          <div className="col-md-3 col-sm-6">
            <CardPrimary
              // image={pustakariSmall}
              image={`${process.env.REACT_APP_BASE_URL}/images/pustakari-small.png`}
              alt="Pustakari"
              title="Pustakari"
              link="product-pustakari"
            />
          </div>
          <div className="col-md-3 col-sm-6">
            <CardPrimary
              // image={smallGundPak}
              image={`${process.env.REACT_APP_BASE_URL}/images/gudpak-small.png`}
              alt="Gundpak"
              title="GundPak"
              link="product-gundpak"
            />
          </div>
          <div className="col-md-3 col-sm-6">
            <CardPrimary
              // image={luxuryBox}
              image={`${process.env.REACT_APP_BASE_URL}/images/makkuse-lux.jpeg`}
              alt="Luxury Box"
              title="Luxury Box"
              link="product-luxury-box"
            />
          </div>
        </div>
        <div className="button-container">
          <Link to="/product" onClick={() => window.scrollTo(0, 0)}>
            <button className="btn-main">VIEW ALL PRODUCTS</button>
          </Link>
        </div>
      </div>
    </section>
  );
};
